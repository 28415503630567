.building-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
  }
  .building {
    border: 1px solid #fff;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    background-color: #fff;
    width: 250px;
  }

  .view-building-button {
    background-color: #0074BB;
    color: #fff;
    padding-top: 15px;
    height: 43px;
    width: 150px;
    cursor: pointer;
    border-radius: 7%;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    /* position: relative;
    left: 322px; */
  }
  
  .building img {
    max-width: 60%;
    height: auto;
    padding-bottom: 7px;
  }

  .building-data {
    font-size: 13px;
    padding-bottom: 7px;
  }

  /* .building-details {
    justify-content: space-between;
    align-items: flex-start;
    gap: 78;
    display: inline-flex;
  } */

  /* .single-building-data{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5;
    display: inline-flex;
  } */

  .building-data{
    word-wrap: break-word;
    
  }

  .building-button {
    background-color: #0074BB;
    color: #fff;
    padding-top: 5px;
    /* height: 63px; */
    width: 150px;
    cursor: pointer;
    border-radius: 7%;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    
  }

  .add-building-button {
    display: flex;
    align-items: center;
  }
  
  .img-building {
    margin-right: 8px; /* Adjust the margin as needed */
  }
  
  .arrow-tag {
    cursor: pointer;
  }