  
  /* .tabs button {
    flex: 1;
    padding: 10px;
    
    background: none;
    margin-right: 5px;
    transition: background 0.3s;
  } */

.container {
    width: 80%;
    /* margin: 0 auto; */
    padding: 20px;
    font-family: Arial, sans-serif;
    margin-bottom: 35em;
    position: relative;
    right: 50px;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 20px;
    
  }

.tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
}

.tabs button {
  margin-right: 5px;
  padding: 10px 15px;
  cursor: pointer;
}

.tabs button:last-child {
  margin-right: 0;
}

.tabs button.active {
  color: #007bff;
  border-bottom: 1px solid #007bff;
}

.add-building-button {
  padding: 10px 15px;
  cursor: pointer;
}

  

  
  .search-filter input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px;
  }
  
  .search-filter button {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
  }
  
  table {
    width: 220%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  table th, table td {
    border-bottom: 1px solid #ccc;
    /* padding: 30px; */
    text-align: left;
  }
  
  table th {
    background: #f9f9f9;
    padding: 28px;
    font-size: 15px;
  }
  
  .add-tenant {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
    float: right;
  }
  