.customer-form-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 33px;
}


@media (max-width: 575px){
    .customer-form-wrapper {
        grid-template-columns: 1fr;
    }
    .img-wrapper {
        grid-template-columns: 1fr;
    }
    .customer-button{
        position: relative;
        right: 0;
        top: 40px;
    }
}

