.img-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    /* grid-gap: 7px; */
    top: 5rem;
   
  }

  
  .customer-button {
    background-color: #0074BB;
    color: #fff;
    padding-top: 15px;
    height: 43px;
    width: 150px;
    cursor: pointer;
    border-radius: 7%;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    position: relative;
    right: 430px;
    top: 120px;
  }

  .header-button {
    background-color: #0074BB;
    color: #fff;
    padding-top: 15px;
    height: 43px;
    width: 150px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    position: relative;
    
    
    
  }
  
  @media (max-width:560px) {
    .img-wrapper {
      grid-template-columns: 1fr;
      position: relative;
    }
    .customer-button {
      margin-right: 223px;
    }
  }