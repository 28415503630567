@media screen and (max-width: 820px){
    .containerFeaures{
        flex-direction: column;
        align-items: flex-start;
    }
    .featuresSearchContainer{
        width: 100% !important ; 
        align-items: flex-start;
        align-self: flex-start;
    }
    .moreFeaturesContainer{
        margin-top: 2rem;
        align-self: flex-start;
    }
}


@media screen and (max-width: 720px){
    .allCustomersContainer{
        flex-direction: column;
        align-items: flex-start;
    }
    .addCustomerBtn{
        align-self: flex-start;
        margin-top: 2rem;
    }
    .allCustomersInnerContainer{
        align-self: flex-start;
    }

    .allCustomersTableContainer{
        overflow-x: hidden !important ;
    }

    .allCustomersTable{
        overflow-x: scroll !important ;
    }

    .allCustomersTable tbody{
        overflow-x: scroll !important;
    }



}

@media screen and (max-width: 380px){
    .allCustomersText{
        font-size: 14px;
    }
}