.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 15px;
  cursor: pointer;
  color: #808080;
  text-decoration: none;
  /* border: 1px solid #F0F2F5; */
  margin: 5px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.tab.active {
  color: #0074BB;
  border-color: #0074BB;
}

.building-button {
  margin: 20px;
  /* margin-left: auto; */
}

.transactions {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 720px) {
  .tabs-container {
    align-items: center;
  }

  .tabs {
    justify-content: center;
  }

  .tab-content {
    width: 90%;
  }

  .building-button {
    /* width: 100%; */
    display: flex;
  }
}
