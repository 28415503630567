
.personalBoxInputContainer{
    margin-bottom: 1rem;
}

@media screen and (max-width:720px) {
    .personalDetailsContainer {
        flex-direction: column;
        width: 100%;
        
    }
    .personalInfoInputContainer{
        width: 100% !important;
    }
}

@media screen and (max-width:550px) {
    .personalDetailsContainer {
        flex-direction: column;
        width: 100%;

    }
    .personInfoInput{
        width: 100% !important;
        display: block;
    }

    .personInfoInputBox{
        margin-top: 1.5rem;
    }

}