.background-img-wrapper {
    background-image: url('../../assets/images/images/Rectangle.png');
    background-size: cover;
    margin-right: 26rem;
    position: relative;
    right: 9rem;
    width: 29em;
}

.auth-header h2 {
    color: #373435;
    font-size: 32px;
    font-style: normal;
    font-family: 'Montserrat';
    font-weight: 800;
}

.auth-header h3 {
    color: #373435;
    font-size: 30px;
    font-style: normal;
    font-family: 'Montserrat';
    font-weight: 800;
}

.auth-header-verify h3 {
    color: #373435;
    font-size: 22px;
    font-style: normal;
    font-family: 'Montserrat';
    font-weight: 800;
}

.auth-header {
    margin-top: 23px;
}

.auth-header h4 {
    font-family: 'Montserrat';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    color: #808080;
    width: 31em;
    margin-top: 20px;
    padding-bottom: 14px;
}

.forgot-password-header {
    margin-top: 75px;
}

.forgot-password-header h4 {
    font-family: 'Montserrat';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    color: #808080;
    width: 35em;
    margin-top: 20px;
}

.login-form-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    right: 30em;
    width: 18em;
}
.login-form-wrapper-verify {
    display: flex;
    flex-direction: column;
    position: relative;
    right: 30em;
    width: 19em;
}

.back-wrapper {
    display: flex;
    align-items: center;
    /* Align items vertically */
    margin-top: 23px;
}

.back-wrapper-forgot-password {
    display: flex;
    align-items: center;
    /* Align items vertically */
    margin-top: 50px;
}

.back-wrapper img {
    margin-right: 5px;
    /* Adjust as needed */
}

.button-wrapper {
    margin-top: 20px;
}
.button-wrapper-verify {
    margin-bottom: 95px;
}

.text-wrapper {
    margin-top: 23px;
}

.row {
    margin: auto;
    /* center horizontally */

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
    position: relative;
    right: 1.5em;
    bottom: 1em;
}

input {
    /* margin: 0.5rem;
    padding: 0.5rem;
    max-width: 5rem;
    text-align: center; */
}

.input-box {
    margin-right: 10px;
    /* Adjust the spacing between text boxes */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}