.appartment-table{
    /* border: 1px solid #fff; */
    padding: 1rem;
    /* border-radius: 8px; */
    /* background-color: #fff; */
    width: 96%;
}

@media screen and (max-width:550px){
    .apartmentHeader{
        flex-direction: column;
        align-items: flex-start;

    }
    .apartmentHeaderText{
        margin: 1rem 0;
        align-self: flex-start;
    }
    .apartmentHeaderBtn {
        align-self: flex-start;
    }
}