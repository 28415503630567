
@media screen and (max-width: 684px){
    .buildingInfoContainer{
        flex-direction: column;
        display: flex;
    }
        .assetsInfoBox,
        .buildingInfoBox{
            margin-bottom: 2rem;
            width: 100%;
        }
}

