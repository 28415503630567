:root { 
--gray_51:#f6fafd; 
--green_200:#92d29b; 
--gray_400_a2:#bdbdbda2; 
--red_300:#ed6a5e; 
--bluegray_900_87:#33333387; 
--gray_50:#fafafa; 
--green_500:#45aa54; 
--green_401:#61c454; 
--light_blue_800:#0074bb; 
--green_400:#66c174; 
--gray_600_a2:#828282a2; 
--black_900:#000000; 
--light_blue_A700:#008be0; 
--gray_600:#808080; 
--gray_402:#c4c4c4; 
--gray_601:#828282; 
--gray_700:#5a5a5a; 
--gray_400:#bdbdbd; 
--gray_301:#dddddd; 
--gray_401:#b7b7b7; 
--gray_302:#daf0dd; 
--gray_800:#4f4f4f; 
--gray_602:#7e7e7e; 
--gray_900:#201f1f; 
--black_900_0c:#0000000c; 
--gray_101:#f2f2f2; 
--gray_200:#f2f1f1; 
--gray_300:#e0e0e0; 
--orange_300:#f4bd50; 
--gray_100:#f2f8fc; 
--bluegray_900:#373435; 
--bluegray_600:#3f8484; 
--bluegray_900_a2:#373435a2; 
--gray_500_99:#a8a6a699; 
--blue_100:#cbebff; 
--white_A701:#fffffe; 
--bluegray_900_19:#37343519; 
--white_A700:#ffffff; 
--bluegray_903:#363636; 
--bluegray_902:#292d32; 
--bluegray_901:#333333; 
--light_blue_800_a2:#0074bba2; 
}