.tenanat-settings-wrapper{
    /* margin-bottom: 4rem; */
}


.settings-header {
    color: #201F1F;
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 600;
    line-height: 28.80;
    word-wrap: break-word;
    margin-top: 7rem;
    margin-bottom: 0;
    padding-bottom: .5rem;
}
.mini-header h3{
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: .5rem;
}

