
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/MontserratRomanExtraBold.ttf"); font-family : "Montserrat"; font-weight : 800;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/MontserratRomanMedium.ttf"); font-family : "Montserrat"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/MontserratRomanRegular.ttf"); font-family : "Montserrat"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/MontserratRomanSemiBold.ttf"); font-family : "Montserrat"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/MontserratRomanBold.ttf"); font-family : "Montserrat"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/ManropeMedium.ttf"); font-family : "Manrope"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/MontserratRomanBlack.ttf"); font-family : "Montserrat"; font-weight : 900;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/MontserratRegular.ttf"); font-family : "Montserrat"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/RubikRomanMedium.ttf"); font-family : "Rubik"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikRomanRegular.ttf"); font-family : "Rubik"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/LatoRegular.ttf"); font-family : "Lato"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/ManropeBold.ttf"); font-family : "Manrope"; font-weight : 700;  }